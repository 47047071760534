<template>
  <div class="contact-wrapper">
    <div class="map">
      <BaiduMap
        class="map-baidu"
        ak="NNE0OftpPKIuUiwGZcIkaCEahsGqgwE9"
        :center="center"
        :zoom="zoom"
        :position="center"
        :scroll-wheel-zoom="true"
      >
        <BmMarker :position="pos">
          <BmLabel :content="$t('name')" :labelStyle="{color: 'red', fontSize : '14px', padding: '4px 8px', borderRadius: '15px'}" :offset="{width: $i18n.locale === 'zh' ? -88 : -38, height: 30}" />
        </BmMarker>
      </BaiduMap>
      <div class="text">
        <div>
          <div class="title">{{ $t('contactInformation') }}</div>
          <div class="list">
            <div>{{ $t('phonenamme') }}: {{ $t('phoneDetails') }}</div>
            <div>{{ $t('email') }}：info@ewinlight.com</div>
            <div>{{ $t('addressname') }}: {{ $t('addressDetails') }}</div>
            <div>{{ $t('wordTime') }}: {{ $t('wordTimeDetails') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="email">
      <div class="title">
        {{ $t('email') }}
      </div>
      <div class="email-form">
        <div class="email-form-item-wrapper">
          <div
            v-for="item in formArray"
            :key="item"
            class="email-form-item"
            :class="item === 'message' ? 'email-form-item-message' : ''"
          >
            <div class="left">
              <img :src="require('@/assets/image/about/'+ item +'.png')" />
            </div>
            <div class="right">
              <div>{{ $t(item) }}:</div>
              <div class="input">
                <input
                  v-if="item  !== 'message'"
                  v-model="form[item]"
                  class="email-form-input"
                  type="text"
                  :placeholder="$t('pleaseEnter') + $t(item)"
                />
                <textarea 
                  v-else
                  v-model="form[item]"
                  class="email-form-input"
                  rows="5"
                  :placeholder="$t('pleaseEnter') + $t(item)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="codeIndex >= 0"
          class="email-form-submit">
          <div class="left">
            <div>{{$t('pleaseEnterTrue')}}: {{ codeArray[codeIndex].question }}?</div>
            <div class="email-form-item">
              <input
                  v-model="codeAnswer"
                  :placeholder="$t('pleaseEnterTrue')"
                />
            </div>
          </div>
          <div class="right">
            <button @click="reset">{{ $t('reset') }}</button>
            <button @click="submit">{{ $t('submit') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { goPost, showMessage } from '@/utils'
import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import BmMarker from 'vue-baidu-map/components/overlays/Marker.vue'
import BmLabel from 'vue-baidu-map/components/overlays/Label.vue'
export default {
  name: 'Contact',
   metaInfo() {
    return {
      title: this.$t('contactUs')
    }
  },

  components: {
    BaiduMap,
    BmMarker,
    BmLabel
  },

  data() {
    return {
      codeArray: [
        {
          question: '6 + 1',
          answer: '7'
        },
        {
          question: '7 + 1',
          answer: '8'
        },
        {
          question: '9 + 0',
          answer: '9'
        },
        {
          question: '11 - 1',
          answer: '10'
        },
        {
          question: '2 + 4',
          answer: '6'
        },
        {
          question: '10 - 9',
          answer: '1'
        },
        {
          question: '1 + 1',
          answer: '2'
        },
        {
          question: '5 - 2',
          answer: '3'
        },
        {
          question: '2 * 2',
          answer: '4'
        },
        {
          question: '10 / 2',
          answer: '5'
        }
      ],
      formArray: ['theme', 'user_name', 'address', 'phone', 'message'],
      form: {
        theme: '',
        phone: '',
        user_name: '',
        address: '',
        message: ''
      },
      formDeep: {},
      codeIndex: -1,
      codeAnswer: '',
      pos: {
        lng: 113.863951,
        lat: 22.612672
      },
      center: {
        lng: 113.861867,
        lat: 22.612601
      },
      zoom: 20
    }
  },
  mounted() {
    this.formDeep = Object.assign({}, this.form)
    this.codeIndex = this.randomNum()
  },
  methods: {
    randomNum() {
      return Math.floor(Math.random()*10)
    },
    reset() {
      this.form = Object.assign({}, this.formDeep)
    },
    async submit() {
      if (this.codeAnswer !== this.codeArray[this.codeIndex].answer) {
        showMessage(this.$t('pleaseEnterTrue'), 'error')
        this.codeIndex = this.randomNum()
        this.codeAnswer = ''
        return false
      }
      const {theme, user_name, address, message} = this.form
      if (theme && user_name && address && message) {
        const result = await goPost('/ewinlight/email', this.form, '#app')
        if (result.code === 200) {
          showMessage(this.$t('success'), 'success')
        } else {
          showMessage(result.msg, 'error')
        }
        this.codeIndex = this.randomNum()
      } else {
        showMessage(this.$t('pleaseEnterTrue') + this.$t('theme') + ',' + this.$t('user_name') + ',' + this.$t('address') + ',' + this.$t('message'), 'error')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-wrapper {
  overflow: hidden;
  .map {
    width: 100%;
    height: 710px;
    overflow: hidden;
    position: relative;
    // background: url('~@/assets/image/about/map.jpg') no-repeat center center;
    .map-baidu {
      width: 100%;
      height: 710px;
    }
    .text {
      height: 100%;
      display: flex;
      align-items: center;
      position: absolute;
      z-index: 8;
      top: 0;
      left: calc((100% - 1200px) / 2);
      &>div{
        background: #fff;
        padding: 85px 50px;
        &>.title {
          color: rgba(51, 51, 51, 1);
          font-size: 42px;
          font-weight: bold;
          margin-bottom: 55px;
          line-height: 50px;
        }
        &>.list {
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
          line-height: 50px;
        }
      }
    }
  }
  .email {
    max-width: 1200px;
    margin: 0 auto;
    .title {
      font-size: 42px;
      color: rgba(51, 51, 51, 1);
      margin: 70px;
      line-height: 50px;
      font-weight: bold;
      text-align: center;
    }
    .email-form {
      margin-bottom: 185px;
      .email-form-item-wrapper {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 124px;
        border-bottom: 1px solid rgba(227, 227, 227, 1);
        margin-bottom: 40px;
        .email-form-item {
          width: calc(50% - 110px);
          margin-right: 110px;
          height: 115px;
          border-top: 1px solid rgba(227, 227, 227, 1);
          display: flex;
          align-items: center;
          .left {
            width: 48px;
            height: 48px;
            margin-right: 32px;
            flex-shrink: 0;
          }
          .right {
            width: 100%;
            &>div:nth-child(1) {
              color: rgba(51, 51, 51, 1);
              font-size: 18px;
              line-height: 32px;
            }
            .email-form-input {
              border: 0;
              outline: none;
              width: 100%;
            }
          }
        }
        .email-form-item-message {
          width: calc(100% - 110px);
          align-items: flex-start;
          padding-top: 20px;
          .right {
            width: 100%;
          }
        }
      }
      .email-form-submit {
        display: flex;
        justify-content: space-between;
        height: 80px;
        align-items: center;
        .left {
          width: 35%;
          height: 100%;
          border-bottom: 1px solid rgba(227, 227, 227, 1);
          &>div:nth-child(1) {
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            margin-bottom: 8px;
            line-height: 32px;
          }
          input {
            border: 0;
            outline: none;
            width: 100%;
          }
        }
        .right {
          button {
            width: 169px;
            height: 68px;
            line-height: 68px;
            color: rgba(255, 255, 255, 1);
            border-radius: 8px;
            overflow: hidden;
            border: 0;
            outline: none;
            margin-left: 30px;
            font-size: 18px;
            cursor: pointer;
            &:nth-child(1) {
              background: rgba(170, 170, 170, 1);
            }
            &:nth-child(2) {
              background: rgba(51, 51, 51, 1);
            }
          }
        }
      }
    }
  }

}
</style>